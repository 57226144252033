<template>
    <v-row>
        <v-col class="pb-0" cols="12">
            <v-btn text to="/chamado/novo"><v-icon left>mdi-plus</v-icon>Novo chamado</v-btn>
        </v-col>
        <v-col cols="12">
            <v-card>
                <v-toolbar color="primary" dark flat height="auto">
                    <v-toolbar-title class="py-2 hidden-xs-only">Filtrar</v-toolbar-title>
                    <v-spacer class="hidden-xs-only"/>
                    <v-row>
                        <v-col cols="6"><SelectSimNao dense label="Concluído:" clearable hide-details flat outlined v-model="filtro.concluido" /></v-col>
                        <v-col cols="6"><v-text-field dense label="Buscar:" hide-details flat outlined v-model="filtro.busca" /></v-col>
                    </v-row>
                </v-toolbar>
                <ChamadoListar :filtro="filtro" />
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import ChamadoListar from "@/Views/Chamado/Component/ChamadoListar"
    import SelectSimNao from "@/Views/Estrutura/Form/SelectSimNao.vue"

    export default {
        name: "Chamado",
        components: {SelectSimNao, ChamadoListar},
        data() {
            return {
                filtro : {
                    concluido : null
                }
            }
        }
    }
</script>

<style scoped>

</style>
