<template>
    <div>
        <v-progress-linear indeterminate absolute color="secondary" :active="carregando" />
        <v-simple-table v-if="chamados.total > 0">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th style="width: 20px;"></th>
                        <th>Chamado</th>
                        <th>Criado por</th>
                        <th>Escola</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(r, i) in chamados.lista" :key="i">
                        <td class="pr-0">
                            <v-avatar size="40" :color="corAvatar(r.chamado)">
                                <span class="white--text headline">{{r.chamado.substr(0, 1)}}</span>
                            </v-avatar>
                        </td>
                        <td :key="r.id_aluno">
                            <router-link exact color="primary" :to="`/chamado/gerenciar/${r.id_chamado}`"><strong>{{r.chamado}}</strong></router-link><br />
                            <small><strong>ID:</strong> {{r.id_chamado}}</small>
                        </td>
                        <td>
                            <router-link exact color="primary" v-if="permissao('usuario_abrir')" :to="`/usuario/gerenciar/${r.usuario_cadastro.id_usuario}`"><strong>{{r.usuario_cadastro.usuario}}</strong></router-link>
                            <strong v-else>{{r.usuario_responsavel.usuario}}</strong>
                            <br /><small>{{dataPt(r.dh_cadastro)}}</small>
                        </td>
                        <td>
                            <router-link exact color="primary" v-if="permissao('franquia_abrir')" :to="`/franquia/gerenciar/${r.franquia.id_franquia}`"><strong>{{r.franquia.franquia}}</strong></router-link>
                            <strong v-else>{{r.franquia.franquia}}</strong>
                        </td>
                        <td>
                          <v-chip small color="green" dark v-if="r.dh_conclusao"><v-icon left small>mdi-check-circle</v-icon> Concluído</v-chip>
                          <v-chip small color="orange" dark v-else><v-icon left small>mdi-close-circle</v-icon> Pendente</v-chip>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-alert v-else type="info" color="blue-grey" text class="mb-0">Registros não encontrados</v-alert>
        <v-divider/>
        <Paginacao :disabled="carregando" @paginar="paginar" :total="chamados.total" />
    </div>
</template>

<script>

import Paginacao from '@/Views/Estrutura/Paginacao'
import axios from "axios"
import {mapState} from "vuex"

export default {
    name: "ChamadoListar",
    props : ['filtro', 'meu', 'id_turma'],
    components: {Paginacao},
    data() {
        return {
            carregando : false,
            chamados : {
                lista : [],
                total : 0
            }
        }
    },
    computed : {
        ...mapState(['baseUrl', 'apiUrl', 'paginationLimit'])
    },
    methods : {
        paginar(pagina) {
            this.carregando = true
            return axios.post(`${this.apiUrl}chamado/listar`, {
                limit     : this.paginationLimit,
                concluido : this.filtro.concluido,
                offset    : this.paginationLimit * pagina,
                busca     : this.filtro.busca || null
            }).then( (res) => {
                this.chamados = res.data
                this.carregando = false
            })
        }
    },
    watch : {
        'filtro.busca' : function () {
            this.paginar(0)
        },
        'filtro.concluido' : function () {
            this.paginar(0)
        }
    },
    activated() {
        this.paginar(0)
    },
    created() {
        this.paginar(0)
    }
}
</script>

<style scoped>

</style>
